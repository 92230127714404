import React from 'react';
import './style.scss';
import '../../assets/fonts/fontello-771c82e0/css/fontello.css';

class Links extends React.Component {
	render() {
		const author = this.props.data;
		const links = {
			bluesky: author.bluesky,
			github: author.github,
			email: author.email,
			linkedin: author.linkedin,
		};

		return (
			<div className="links">
				<ul className="links__list">
					<li className="links__list-item">
						<a
							href={`https://bsky.app/profile/${links.bluesky}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							🦋
						</a>
					</li>
					<li className="links__list-item">
						<a
							href={`https://www.github.com/${links.github}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="icon-github" />
						</a>
					</li>
					<li className="links__list-item">
						<a
							href={`https://www.linkedin.com/in/${links.linkedin || 'foysalit'}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="icon-linkedin" />
						</a>
					</li>
					<li className="links__list-item">
						<a href={`mailto:${links.email}`}>
							<i className="icon-mail" />
						</a>
					</li>
				</ul>
				<ul className="links__list">
					<li>
						<a
							target="_blank"
							rel="noopener noreferrer"
							className="links__list-item-link"
							href="https://www.codementor.io/foysalit"
						>
							<img
								src="https://cdn.codementor.io/badges/book_session_github.svg"
								alt="Book session on Codementor"
							/>
						</a>
					</li>
				</ul>
			</div>
		);
	}
}

export default Links;
